import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
// import { makeStyles, Typography, Button, MenuItem, Link } from '@material-ui/core';
import { Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '../../icons/CheckBoxIcon';
import CheckBoxUncheckedIcon from '../../icons/CheckBoxUncheckedIcon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
// import { styled } from '@mui/system';
import { styled /*, Theme*/ } from '@material-ui/core/styles';
import { useAppState } from '../../state';

// Images
import Partner from '../LoginPage/partner';
import TruBeLogo from '../LoginPage/TruBeLogo';

// styled('div')({
const CustomFormControlLabel = styled(FormControlLabel)({
  // (({ theme }) => ({
  // const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  // padding: theme.spacing(1),
  padding: '16px',
  borderRadius: '8px',
  marginLeft: '0',
  marginRight: '0',
  marginTop: '32px',
  marginBottom: '32px',
  // border: '1px solid #ccc',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  color: '#ffffff',
  '&:hover': {
    // backgroundColor: '#f5f5f5',
  },
});
// }));

const useStyles = makeStyles((theme: Theme) => ({
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  errorMessage: {
    color: 'red',
    display: 'flex',
    'align-items': 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      'margin-right': '0.4em',
    },
  },
  container: {
    'margin-top': '80px', //'2.5em',
    padding: '24px',
    // height: '100%',
    // 'margin-bottom': '2.5em',
  },
  partnerLabelContainer: {
    position: 'relative',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'margin-bottom': '52px',
    'background-image': Partner,
    'background-size': '100%',
    'background-repeat': 'no-repeat',
    width: '107px',
    height: '36px',
    [theme.breakpoints.down('sm')]: {
      // width: '100%',
      // height: '100px',
      // backgroundPositionY: '140px',
    },
  },
  trubeLogo: {
    // position: 'absolute',
    // top: 0,
    // left: '0',
    'margin-bottom': '10px',
  },
  gutterBottom: {
    'margin-bottom': '1em',
  },
  greyColor: {
    color: theme.grey,
  },
  redColor: {
    color: theme.red,
  },
  paragraph: {
    marginBottom: '32px',
  },
  passwordContainer: {
    // 'min-height': '120px',
    'margin-bottom': '24px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  linkLikeButton: {
    'text-decoration': 'underline',
  },
  phoneCodeInput: {
    'margin-right': '8px',
    // display: 'flex',
    // border: '1px solid rgb(224, 234, 243)',
    // 'border-radius': '8px',
    // padding: '14px 16px',
    // 'margin-right': '8px'
  },

  closeBackgroundSelection: {
    cursor: 'pointer',
    display: 'flex',
    background: 'transparent',
    border: '0',
    padding: '0',
  },
}));

const TermsAndConditionsAcceptancePage = () => {
  const classes = useStyles();
  const location = useLocation<{ from: Location }>();
  const history = useHistory();
  const { user, updatePartner } = useAppState();

  const [isAccepted, setIsAccepted] = useState(false);

  const handleCheckboxChange = () => {
    setIsAccepted(!isAccepted);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isAccepted) {
      // Логика для обработки принятия условий
      console.log('Условия приняты');
      // @ts-ignore
      updatePartner(user.id, { hasAcceptedTerms: true }).then(() => {
        history.replace(location?.state?.from || { pathname: '/' });
      });
    } else {
      // alert("Пожалуйста, примите условия.");
    }
  };

  const coachName = user?.firstName;

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      // spacing={3}
      className={classes.container}
    >
      <Grid item xs={12} md={3}>
        <div
          // display="flex"
          // alignItems="center"
          // justifyContent="center"
          // flexDirection="column"
          // textAlign="center"
          // flexGrow={1}
          // // mb={5}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            flexGrow: 1,
          }}
        >
          <TruBeLogo className={classes.trubeLogo} />
          <div className={classes.partnerLabelContainer} />
          {/*<img src={Partner} />*/}
          {/*process.env.REACT_APP_SET_AUTH === 'firebase' && (
          <>
            <Typography variant="h5" className={classes.gutterBottom}>
              Sign in to join a room
            </Typography>
            <Typography variant="body1">Sign in using your Twilio Google Account</Typography>
            <Button variant="contained" className={classes.googleButton} onClick={login} startIcon={<GoogleLogo />}>
              Sign in with Google
            </Button>
          </>
        )*/}

          {/*<Typography variant="h5" className={classes.gutterBottom}>
          Enter password to join a room
        </Typography>*/}
          <form onSubmit={handleSubmit}>
            <h2 className="" style={{ marginBottom: '32px' }}>
              Hello {coachName},
            </h2>
            <Typography variant="body1" className={classes.paragraph}>
              We have updated our Terms & Conditions. Please, read the updated version and accept it by ticking the box
              below in order to continue using the App. <br />
              <br />
              Thank you, <br />
              <br />– Team TruBe
            </Typography>
            <Link
              href={
                user?.region === 'GB'
                  ? 'https://trubeapp.com/terms/partner-uk.html'
                  : 'https://trubeapp.com/terms/service-provider-agreement.html'
              }
              target="_blank"
              rel="noopener"
              className={clsx(classes.linkLikeButton, classes.redColor)}
            >
              Updated Terms & Conditions
            </Link>
            <CustomFormControlLabel
              control={
                <Checkbox
                  checked={isAccepted}
                  // onChange={toggleArea(listDataItem)}
                  // value="checkedA"
                  checkedIcon={<CheckBoxIcon />}
                  icon={<CheckBoxUncheckedIcon />}
                  // inputProps={{ 'aria-label': 'Checkbox A' }}
                  // onToggle={handle
                  style={{
                    alignSelf: 'center',
                  }}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="I have read and accepted the new Terms & Conditions"
            />

            <Button
              disabled={!isAccepted}
              variant="contained"
              color="primary"
              type="submit"
              className={classes.submitButton}
              // onClick={handleSubmit}
            >
              Save
              {/*{isFetching && (
	            <Box
	              display="flex"
	              className={clsx(classes.loaderSpinner)}
	              sx={{
	                marginRight: '4px',
	              }}
	            >
	              <LoaderSpinnerIcon />
	            </Box>
	          )}*/}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditionsAcceptancePage;
