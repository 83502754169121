import React, { PropsWithChildren, useState, useEffect, useRef, FormEvent, ChangeEvent } from 'react';
import { ScrollView } from 'react-native';
import clsx from 'clsx';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

// import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { withStyles, Theme } from '@material-ui/core/styles';
// import IntroContainer from '../IntroContainer/IntroContainer';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';
import RoundedContainer from '../RoundedContainer/RoundedContainer';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import Divider from '@material-ui/core/Divider';

// import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';

import UserPlaceholder from '../../icons/UserPlaceholder80';
// import LocationIcon from '../../icons/LocationIcon';
// import CloseIcon from '../../icons/Close2Icon';
// import ArrowRightIcon from '../../icons/ArrowRightIcon';
// import PlusIcon from '../../icons/PlusIcon';
// import UserPlaceholderInPerson from '../../icons/UserPlaceholderInPerson';
// import UserPlaceholderOnline from '../../icons/UserPlaceholderOnline';
// import PhoneIcon from '../../icons/PhoneIcon';
// import CalendarIcon from '../../icons/CalendarIcon';
// import QuestionOIcon from '../../icons/QuestionOIcon';
// import Calendar64Icon from '../../icons/Calendar64Icon';
// import Location64Icon from '../../icons/Location64Icon';
// import SessionOnline64Icon from '../../icons/SessionOnline64Icon';
// import WoKickboxingIcon from '../../icons/WoKickboxingIcon';
// import CopyIcon from '../../icons/CopyIcon';
// import FullStarIcon from '../../icons/FullStarIcon';

import CheckBoxIcon from '../../icons/CheckBoxIcon';
import CheckBoxUncheckedIcon from '../../icons/CheckBoxUncheckedIcon';

import LoaderSpinnerIcon from '../../icons/LoaderSpinnerIcon';

// import BottomNavigation from '../BottomNavigation/BottomNavigation';
import StarsRatingBlock from '../StarsRatingBlock/StarsRatingBlock';
import RateSessionDialog from '../RateSessionDialog/RateSessionDialog';

// import SessionInPersonListItemIcon from './SessionInPersonListItemIcon';
// import SessionOnlineListItemIcon from './SessionOnlineListItemIcon';

import { getMomentWithTz } from '../../utils/date';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';

const MIN_REVIEW_LENGHT = 50;
const MAX_REVIEW_LENGHT = 512;

const useStyles = makeStyles((theme: Theme) => ({
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  // errorMessage: {
  //   color: 'red',
  //   display: 'flex',
  //   'align-items': 'center',
  //   margin: '1em 0 0.2em',
  //   '& svg': {
  //     'margin-right': '0.4em',
  //   },
  // },
  gutterBottom: {
    'margin-bottom': '.5rem',
  },
  // gutterBottomBase: {
  //   'margin-bottom': '1rem',
  // },
  // gutterBottomExtraLarge: {
  //   'margin-bottom': '1.5em',
  // },
  // gutterTopDoubleMedium: {
  //   'margin-top': '2em',
  // },
  // gutterBottomDoubleMedium: {
  //   'margin-bottom': '2em',
  // },
  // gutterTopFull: {
  //   'margin-top': '64px'
  // },
  // gutterBottomExtraExtraLarge: {
  //   'margin-bottom': '80px'
  // },
  // passcodeContainer: {
  //   'min-height': '120px',
  // },
  // bottomCtaContainer: {
  //   position: 'fixed',
  //   bottom: '100px',
  //   left: '0',
  //   'background-color': theme.whiteBasic,
  //   'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)',
  //   'z-index': 1099
  // },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  // whiteBasicColor: {
  //   color: theme.whiteBasic,
  // },
  // trueblackBasicColor: {
  //   color: theme.trueblackBasic,
  // },
  // redColor: {
  //   color: theme.red,
  // },
  greyColor: {
    color: theme.grey,
  },
  greyMainColor: {
    color: theme.greyMain,
  },
  // greenMainColor: {
  //   color: theme.greenMain,
  // },
  // root: {
  //   'justify-content': 'center',
  //   color: theme.trueblackBasic,
  //   'min-width': '24px'
  // },
  // rootArrow: {
  // 	color: theme.red,
  // 	'min-width': '24px'
  // },
  // closeBackgroundSelection: {
  //   cursor: 'pointer',
  //   display: 'flex',
  //   background: 'transparent',
  //   border: '0',
  //   padding: '0',
  // },

  // linkLikeButton: {
  //   'text-decoration': 'underline'
  // },

  userProfilePictureContainer: {
    width: '80px',
    height: '80px',
    margin: '0 auto',
    'border-radius': '50%',
    overflow: 'hidden',

    // width: '64px',
    // height: '64px',
    // // margin: 0,
    // // borderWidth: 1px;
    // // border: 2px solid #e5e5e5;
    // 'border-radius': '50%',
    // // margin: 0 auto;
  },

  userPictureImg: {
    width: '100%',
    height: 'auto',
    'text-indent': '100%',
    'white-space': 'nowrap',
  },

  submitButtonContainer: {
    position: 'relative',
    width: '100%',
    // 'margin-right': '1em',
  },
  submitButtonLoadingSpinner: {
    '& > svg': {
      right: '11px',
      top: '20px',
      position: 'absolute',
      'pointer-events': 'none',
      animation: '$spin 1s linear infinite',
      display: 'inline-block',
      'vertical-align': 'text-bottom',
      fill: 'white',
      'z-index': 2,
    },
  },
  '@keyframes spin': {
    '100%': {
      'webkit-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },
}));

// // TODO: make component
// const GreyRoundedBox = withStyles((theme) => ({
//   root: {
//     height: "100%",
//     // 'border-top-left-radius': '8px',
//     // 'border-top-right-radius': '8px',
//     'border-radius': '8px',
//     'background-color': theme.greyLight,
//     // 'padding-top': 10,
//     // 'padding-bottom': 10,
//   }
// }))(Box);

// // Fixed number of columns
// const gridContainer = {
//   display: "grid",
//   gridTemplateColumns: "repeat(2, 1fr)"
// };

// // Variable number of columns
// const gridContainer2 = {
//   display: "grid",
//   gridAutoColumns: "1fr",
//   gridAutoFlow: "column"
// };

// const gridItem = {
//   height: "100%",
//   // margin: "8px",
//   // border: "1px solid red"
// };

const FixedContainer = withStyles({
  // root: {
  //   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  //   borderRadius: 3,
  //   border: 0,
  //   color: 'white',
  //   height: 48,
  //   padding: '0 30px',
  //   boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  // },
  maxWidthXs: {
    maxWidth: '242px',
  },
  // label: {
  //   textTransform: 'capitalize',
  // },
})(Container);

interface RateSessionProps {
  // open: boolean;
  trainerName?: string;
  // sessionType: string;
  // onClick(): void;
  // onClose(): void;
}

export default function RateSession(props: PropsWithChildren<RateSessionProps>) {
  const classes = useStyles();
  const { user, booking, setBooking, rateCustomer, isFetching /*, isAuthReady*/ } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [passcode, setPasscode] = useState('');
  const [userStrengthRating, setUserStrengthRating] = useState(0);
  const [userEnduranceRating, setUserEnduranceRating] = useState(0);
  const [userMobilityRating, setUserMobilityRating] = useState(0);
  const [userOverallRating, setUserOverallRating] = useState(0);
  // const [authError, setAuthError] = useState<Error | null>(null);
  const [rateSessionError, setRateSessionError] = useState('');

  const [rateSessionOpen, setRateSessionOpen] = useState(false);

  const [review, setReview] = useState('');
  const [canSendReview, setCanSendReview] = useState(false);
  const [rateSessionStep, setRateSessionStep] = useState(true);
  const [submitReviewStep, setSubmitReviewStep] = useState(false);
  const [submitReportStep, setSubmitReportStep] = useState(false);
  const [hasReportBeenSubmitted, setHasReportBeenSubmitted] = useState(false);

  const [reason, setReason] = useState<number | null>(null);

  // const isAuthEnabled = true; // Boolean(process.env.REACT_APP_SET_AUTH);

  const textInputRef = useRef<HTMLTextAreaElement>(null);

  // const setUserRating = (rating) => {
  //   // console.log(index);
  //   setDrive(index);
  // };

  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(email, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/', search: window.location.search });
  //     })
  //     .catch(err => {
  //       setAuthError(err);
  //       console.log(err);
  //     });
  // };

  useEffect(() => () => setBooking(null), []);

  const handleReviewChange = React.useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      var value = e.target.value;

      if (!value) {
        setCanSendReview(false);
      } else if (submitReportStep && value.length <= MAX_REVIEW_LENGHT) {
        setCanSendReview(true);
      } else if (value.length >= MIN_REVIEW_LENGHT && value.length <= MAX_REVIEW_LENGHT) {
        setCanSendReview(true);
      } else {
        setCanSendReview(false);
      }

      setReview(value);
    },
    [submitReportStep]
  );

  const handleReasonChange = React.useCallback((reasonNb: any) => {
    // var checked = e.target.checked;

    // if (checked) {
    setReason(reasonNb);
    // }
  }, []);

  const handleSubmit = React.useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const comment = textInputRef.current?.value || ''; //(e.target as HTMLInputElement)[0].value; // e.target.inputs;

      var reasonStr = '';

      switch (reason) {
        case 0:
          reasonStr = "Client didn't attend";

          break;
        case 1:
          reasonStr = 'I missed the session';

          break;
        case 2:
          reasonStr = 'Member issue';

          break;
        case 3:
          reasonStr = 'I missed the session';

          break;
        default:
          reasonStr = 'Something else';
      }

      setRateSessionError('');

      rateCustomer(
        user?.id!,
        // booking?.customer?.id!,
        booking?.id!,
        // userStrengthRating,
        // userEnduranceRating,
        // userMobilityRating,
        // userOverallRating,
        hasReportBeenSubmitted ? `Reason: ${reasonStr}.${review ? ` Additional notes: ${review}` : ''}` : comment
      )
        .then(() => !hasReportBeenSubmitted && setRateSessionOpen(true))
        .catch(err => {
          if (err) {
            //.code === 422 || err.code === 500) {
            setRateSessionError(err.message);
          }

          // There was an issue
          if (!hasReportBeenSubmitted) {
            setRateSessionOpen(true);
          }

          // if (e.code === 413) {
          // } else {
          //   setFileSendError('There was a problem uploading the file. Please try again.');
          // }
        })
        .finally(() => {
          // setRateSessionOpen(true);
        });
    },
    [
      user,
      booking,
      userStrengthRating,
      userEnduranceRating,
      userMobilityRating,
      userOverallRating,
      rateCustomer,
      setRateSessionOpen,
      setRateSessionError,
      review,
      reason,
      hasReportBeenSubmitted,
    ]
  );

  const handleRateSessionDialogClose = () => {
    setRateSessionOpen(false);
    history.push({ pathname: `${location?.state?.from ? location?.state?.from : '/sessions/' + booking?.id}` });
    // setMenuOpen(false);
  };

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  return (
    <>
      <ScrollToTopOnMount />
      <RoundedContainer>
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <ScrollView
            // key={`${this.props.scrollEnabled}`}
            // ref={this.props.refCallback}
            // onScroll={this.props.onMomentumScrollEnd}
            contentContainerStyle={{
              height: '100%',
            }}
            style={{
              overflow: 'scroll',
              width: '100%',
              height: '100%',
              // padding: '24px 16px',
              // 'background-color': 'rgb(255, 255, 255)',
              // 'border-radius': '8px',
              // overflow: 'scroll'
              paddingTop: '64px',
              paddingBottom: '24px',
              flexGrow: 1,
              // ml={1}
            }}
          >
            <div style={{ marginTop: hasReportBeenSubmitted ? '108px' : '', marginBottom: '8px', textAlign: 'center' }}>
              {/*<Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={8}
        mb={3}
      >*/}
              {/*<Box
          flexGrow="1"
          // ml={1}
          textAlign="center"
        >*/}
              {/*<Box
            display="flex"
            alignItems="center"
            // justifyContent="space-between"
            mb={1}
          >
            <Box
              display="flex"
              className={clsx(classes.greyColor)}
              mr={1}
            >
              <WoKickboxingIcon />
            </Box>

            <Typography variant="caption" color="primary" className={classes.gutterBottom}>
                Rate the Session With
            </Typography>
          </Box>*/}

              {!submitReportStep && (
                <Typography
                  variant="body1"
                  color="primary"
                  className={classes.gutterBottom}
                  style={{
                    display: 'inline-block',
                    color: 'rgb(107, 113, 120)' /* #6B7178 */,
                    // textTransform: 'uppercase',
                  }}
                >
                  {submitReviewStep ? 'Review the Session with' : 'You finished a session with'}
                </Typography>
              )}

              {!submitReportStep && (
                <Typography
                  variant="body2"
                  color="primary"
                  className={''}
                  style={{
                    marginBottom: submitReviewStep ? '16px' : '8px',
                  }}
                >
                  {booking?.customer?.firstName || 'Peter'} {booking?.customer?.lastName || 'Parker'}
                </Typography>
              )}

              {!submitReportStep && (
                <Container maxWidth="sm">
                  <Typography
                    variant="body1"
                    color="primary"
                    className={classes.greyColor}
                    style={{
                      marginBottom: '16px',
                      // color: 'rgb(107, 113, 120)' /* #6B7178 */,
                    }}
                  >
                    {submitReviewStep && !hasReportBeenSubmitted ? (
                      <span>
                        How was your Member’s experience? Did they achieve their goals? Where was progress made? Did
                        everyone have fun?
                        <br />
                        <br />
                        <span style={{ color: 'rgb(233, 78, 50)' }}>Note:</span> 50 characters minimum
                      </span>
                    ) : hasReportBeenSubmitted ? (
                      <span>
                        Thank you for your feedback. Our Coaching Team will contact you soon to discuss the issue.
                      </span>
                    ) : (
                      getMomentWithTz(new Date(booking?.start), user?.region!).format('H:mma dddd, MMMM DD')
                    )}
                  </Typography>
                </Container>
              )}

              {!submitReviewStep && !submitReportStep ? (
                <div style={{ marginTop: '16px', marginBottom: '88px' }}>
                  {booking?.customer?.photo ? (
                    <div className={classes.userProfilePictureContainer}>
                      <img
                        className={classes.userPictureImg}
                        itemProp="image"
                        src={booking?.customer?.photo + '?size=128x128'}
                        srcSet={`${booking?.customer?.photo}?size=128x128 1x, ${booking?.customer?.photo}?size=256x256 2x`}
                        alt={`Customer profile`}
                      />
                    </div>
                  ) : (
                    <div className={classes.userProfilePictureContainer}>
                      <UserPlaceholder />
                    </div>
                  )}
                </div>
              ) : null}

              {/*<Typography variant="body1" className={classes.greyColor} style={{ 'margin-bottom': 40 }} >
              32, male
          </Typography>*/}

              {!submitReviewStep || submitReportStep ? (
                <Typography
                  variant="body2"
                  color="primary"
                  // className={classes.greyColor}
                  style={{
                    marginBottom: submitReportStep ? '24px' : '116px',
                    // color: 'rgb(107, 113, 120)' /* #6B7178 */,
                  }}
                >
                  {submitReportStep
                    ? hasReportBeenSubmitted
                      ? rateSessionError
                        ? 'Report Was Not Submitted'
                        : 'Report Submitted'
                      : 'What was the issue?'
                    : 'How was the session'}
                </Typography>
              ) : null}

              {hasReportBeenSubmitted && (
                <Container maxWidth="sm">
                  <Typography
                    variant="body1"
                    color="primary"
                    className={classes.greyColor}
                    style={{
                      marginBottom: '16px',
                      // color: 'rgb(107, 113, 120)' /* #6B7178 */,
                    }}
                  >
                    <span>
                      {rateSessionError
                        ? rateSessionError
                        : 'Thank you for your feedback. Our Coaching Team will contact you soon to discuss the issue.'}
                    </span>
                  </Typography>
                </Container>
              )}

              {submitReportStep && !hasReportBeenSubmitted ? (
                <FixedContainer maxWidth="xs" style={{ marginBottom: '32px' }}>
                  <ListItem
                    button
                    style={{
                      // // ...style,
                      paddingLeft: '0', // 16,
                      paddingRight: '0', // 16,
                      paddingTop: '0', // 16,
                      paddingBottom: '0', // 16,
                      // 'padding-left': '0', // 16,
                      // 'padding-right': '0', // 16,
                      // // 'margin-left': 16,
                      // // 'margin-right': 16,
                      // 'background-color': 'white',
                      // // display="flex"
                      // // // alignItems="center"
                      // // alignItems="start"
                      // // justifyContent="space-between"
                      // // flex="1"
                      // //   // maxHeight: '80px',
                      // //   overflow: 'hidden',
                    }}
                    // key={`${key}_${checked}`}
                    // onClick={toggleArea(listDataItem, !checked)}
                    onClick={() => handleReasonChange(0)}
                  >
                    <Checkbox
                      checked={reason === 0}
                      // onChange={toggleArea(listDataItem)}
                      // value="checkedA"
                      checkedIcon={<CheckBoxIcon />}
                      icon={<CheckBoxUncheckedIcon />}
                      // inputProps={{ 'aria-label': 'Checkbox A' }}
                      // onToggle={handle
                      style={{
                        alignSelf: 'center',
                      }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'body1',
                        display: 'inline',
                        color: 'primary',
                      }}
                      // secondaryTypographyProps={{
                      //   variant: 'subtitle1',
                      //   display: 'inline',
                      //   color: 'primary',
                      // }}
                      primary={"Client didn't attend"}
                      // secondary={
                      //   "ash"
                      // }
                      // style={{ marginLeft: 16 }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    style={{
                      // // ...style,
                      paddingLeft: '0', // 16,
                      paddingRight: '0', // 16,
                      paddingTop: '0', // 16,
                      paddingBottom: '0', // 16,
                      // 'padding-left': '0', // 16,
                      // 'padding-right': '0', // 16,
                      // // 'margin-left': 16,
                      // // 'margin-right': 16,
                      // 'background-color': 'white',
                      // // display="flex"
                      // // // alignItems="center"
                      // // alignItems="start"
                      // // justifyContent="space-between"
                      // // flex="1"
                      // //   // maxHeight: '80px',
                      // //   overflow: 'hidden',
                    }}
                    // key={`${key}_${checked}`}
                    // onClick={toggleArea(listDataItem, !checked)}
                    onClick={() => handleReasonChange(1)}
                  >
                    <Checkbox
                      checked={reason === 1}
                      // checked={checked}
                      // onChange={toggleArea(listDataItem)}
                      // value="checkedA"
                      checkedIcon={<CheckBoxIcon />}
                      icon={<CheckBoxUncheckedIcon />}
                      // inputProps={{ 'aria-label': 'Checkbox A' }}
                      // onToggle={handle
                      style={{
                        alignSelf: 'center',
                      }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'body1',
                        display: 'inline',
                        color: 'primary',
                      }}
                      // secondaryTypographyProps={{
                      //   variant: 'subtitle1',
                      //   display: 'inline',
                      //   color: 'primary',
                      // }}
                      primary={'I missed the session'}
                      // secondary={
                      //   "ash"
                      // }
                      // style={{ marginLeft: 16 }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    style={{
                      // // ...style,
                      paddingLeft: '0', // 16,
                      paddingRight: '0', // 16,
                      paddingTop: '0', // 16,
                      paddingBottom: '0', // 16,
                      // 'padding-left': '0', // 16,
                      // 'padding-right': '0', // 16,
                      // // 'margin-left': 16,
                      // // 'margin-right': 16,
                      // 'background-color': 'white',
                      // // display="flex"
                      // // // alignItems="center"
                      // // alignItems="start"
                      // // justifyContent="space-between"
                      // // flex="1"
                      // //   // maxHeight: '80px',
                      // //   overflow: 'hidden',
                    }}
                    // key={`${key}_${checked}`}
                    // onClick={toggleArea(listDataItem, !checked)}
                    onClick={() => handleReasonChange(2)}
                  >
                    <Checkbox
                      checked={reason === 2}
                      // checked={checked}
                      // onChange={toggleArea(listDataItem)}
                      // value="checkedA"
                      checkedIcon={<CheckBoxIcon />}
                      icon={<CheckBoxUncheckedIcon />}
                      // inputProps={{ 'aria-label': 'Checkbox A' }}
                      // onToggle={handle
                      style={{
                        alignSelf: 'center',
                      }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'body1',
                        display: 'inline',
                        color: 'primary',
                      }}
                      // secondaryTypographyProps={{
                      //   variant: 'subtitle1',
                      //   display: 'inline',
                      //   color: 'primary',
                      // }}
                      primary={'Member issue'}
                      // secondary={
                      //   "ash"
                      // }
                      // style={{ marginLeft: 16 }}
                    />
                  </ListItem>
                  <ListItem
                    button
                    style={{
                      // // ...style,
                      paddingLeft: '0', // 16,
                      paddingRight: '0', // 16,
                      paddingTop: '0', // 16,
                      paddingBottom: '0', // 16,
                      // 'padding-left': '0', // 16,
                      // 'padding-right': '0', // 16,
                      // // 'margin-left': 16,
                      // // 'margin-right': 16,
                      // 'background-color': 'white',
                      // // display="flex"
                      // // // alignItems="center"
                      // // alignItems="start"
                      // // justifyContent="space-between"
                      // // flex="1"
                      // //   // maxHeight: '80px',
                      // //   overflow: 'hidden',
                    }}
                    // key={`${key}_${checked}`}
                    // onClick={toggleArea(listDataItem, !checked)}
                    onClick={() => handleReasonChange(3)}
                  >
                    <Checkbox
                      checked={reason === 3}
                      // checked={checked}
                      // onChange={toggleArea(listDataItem)}
                      // value="checkedA"
                      checkedIcon={<CheckBoxIcon />}
                      icon={<CheckBoxUncheckedIcon />}
                      // inputProps={{ 'aria-label': 'Checkbox A' }}
                      // onToggle={handle
                      style={{
                        alignSelf: 'center',
                      }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        variant: 'body1',
                        display: 'inline',
                        color: 'primary',
                      }}
                      // secondaryTypographyProps={{
                      //   variant: 'subtitle1',
                      //   display: 'inline',
                      //   color: 'primary',
                      // }}
                      primary={'Something else'}
                      // secondary={
                      //   "ash"
                      // }
                      // style={{ marginLeft: 16 }}
                    />
                  </ListItem>
                </FixedContainer>
              ) : null}

              {/*<StarsRatingBlock
                className={clsx(classes.gutterBottom, classes.greyMainColor)}
                title="Strength"
                // rating={4}
                onRatingSet={setUserStrengthRating}
              />

              <StarsRatingBlock
                className={clsx(classes.gutterBottom, classes.greyMainColor)}
                title="Endurance"
                onRatingSet={setUserEnduranceRating}
              />

              <StarsRatingBlock
                className={clsx(classes.gutterBottom, classes.greyMainColor)}
                title="Mobility"
                onRatingSet={setUserMobilityRating}
              />

              <StarsRatingBlock
                className={clsx(classes.gutterBottom, classes.greyMainColor)}
                title="How was the session?"
                onRatingSet={setUserOverallRating}
              />*/}

              {/*</Box>*/}
              {/*</Box>*/}
            </div>

            {/*<GreyRoundedBox
        // display="flex"
        pt={2}
        pl={2}
        pr={2}
        pb={2}
        mb={3}
        sx={{
          padding: 16
        }}
        style={{
          height: 144
        }}
      >

        <Typography variant="subtitle2" color="primary" style={{ color: '#6B7178' }}>
          Please add some notes
        </Typography>

      </GreyRoundedBox>*/}

            <Container maxWidth="sm" className={classes.gutterBottom}>
              {(submitReviewStep || submitReportStep) && !hasReportBeenSubmitted ? (
                <>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{
                      marginBottom: '4px',
                    }}
                  >
                    {submitReportStep ? 'Additional notes:' : 'Your review:'}
                  </Typography>
                  <TextareaAutosize
                    ref={textInputRef}
                    maxRows={4}
                    aria-label="Notes"
                    placeholder={submitReportStep ? 'Describe the situation' : 'Describe the session'}
                    minLength={submitReportStep ? undefined : 50}
                    maxLength={MAX_REVIEW_LENGHT}
                    style={{
                      width: '100%',
                      height: '144px',
                      borderRadius: '8px',
                      border: '1px solid #E0EAF3', // #??
                      // backgroundColor: 'rgb(248, 248, 248)', // #F8F8F8
                      padding: '11px 16px',
                      marginBottom: '24px',
                      color: 'rgb(107, 113, 120)', // #6B7178
                      fontSize: '16px',
                      fontFamily: 'Poppins',
                      fontWeight: 400,
                    }}
                    value={review}
                    onChange={handleReviewChange}
                    // defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  />
                </>
              ) : (
                <div />
              )}
            </Container>

            {/*<Container maxWidth="sm" className={classes.gutterBottom} style={{ flexGrow: 1 }}>*/}

            <Grid
              container
              // justifyContent="center"
              // justifyContent="flex-end"
              alignItems="flex-end"
              className={classes.submitButtonContainer}
              style={{
                // marginBottom: '16px',
                paddingLeft: '16px',
                paddingRight: '16px',
                flexGrow: submitReportStep || submitReviewStep || hasReportBeenSubmitted ? 1 : 0,
              }}
            >
              {hasReportBeenSubmitted && (
                <Button
                  variant="contained"
                  color="primary"
                  // disabled={!userStrengthRating || !userEnduranceRating || !userMobilityRating || !userOverallRating}
                  // disabled={!canSendReview}
                  className={classes.submitButton}
                  style={{
                    display: 'inline-flex',
                  }}
                  onClick={() =>
                    history.push({
                      pathname: `${location?.state?.from ? location?.state?.from : '/sessions/' + booking?.id}`,
                    })
                  }
                >
                  Ok
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                type={'submit'}
                // disabled={!userStrengthRating || !userEnduranceRating || !userMobilityRating || !userOverallRating}
                disabled={!canSendReview}
                className={classes.submitButton}
                style={{
                  display: (submitReviewStep || submitReportStep) && !hasReportBeenSubmitted ? 'inline-flex' : 'none',
                }}
                onClick={() => (submitReportStep ? setHasReportBeenSubmitted(true) : setRateSessionOpen(true))}
              >
                {submitReportStep ? 'Submit Report' : 'Submit review'}
              </Button>

              {isFetching && (
                <Box
                  display="flex"
                  className={clsx(classes.submitButtonLoadingSpinner)}
                  sx={{
                    marginRight: '4px',
                  }}
                >
                  <LoaderSpinnerIcon />
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                // disabled={!userStrengthRating || !userEnduranceRating || !userMobilityRating || !userOverallRating}
                // disabled={!password.length}
                className={classes.submitButton}
                style={{
                  display: submitReviewStep || submitReportStep ? 'none' : 'inline-flex',
                }}
                onClick={() => setSubmitReviewStep(true)}
              >
                Everything was great
              </Button>
              {/*</div>*/}

              {!submitReviewStep && !submitReportStep ? (
                <div
                  className={classes.submitButtonContainer}
                  style={{
                    marginTop: '16px',
                    marginBottom: '64px',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    // type="submit"
                    // disabled={cancelledByCustomer || isFetching}
                    className={classes.submitButton}
                    style={{
                      backgroundColor: 'rgb(48, 55, 64)',
                    }}
                    onClick={() => setSubmitReportStep(true)}
                  >
                    There was an issue
                  </Button>
                </div>
              ) : null}
            </Grid>
          </ScrollView>
        </form>
      </RoundedContainer>
      <RateSessionDialog open={rateSessionOpen} text={rateSessionError} onClose={handleRateSessionDialogClose} />
    </>
  );
}
